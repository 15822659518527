<template>
  <div>
    <div class="header">
      <div class="centerContent">
        <img src="../assets/img/logo.png" alt="" class="logo" >
        <div class="nav">
          <ul>
            <li v-for="(item, index) in routerList" :key="index" @mouseenter="mouseenterNavAni(index)" @mouseleave="mouseeleaveNavAni(index)">
              <router-link :to="{ path: item.path }" active-class="active" >{{ item.name }}</router-link>
              <i class="navBg" :id="`navBg`+index"></i>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="holder"></div>
  </div>
</template>

<script>
import navAmi from '@/assets/animation/navAmi.json'
import lottie from "lottie-web";
import mapAmi from "@/assets/animation/map.json";

export default {
  name: 'Header',
  data(){
    return{
      routerList: [
        { path: '/index', name: '首页'},
        { path: '/companyCulture', name: '企业文化'},
        { path: '/joinUs', name: '加入我们'},
        { path: '/contactUs', name: '联系我们'},
      ],
      navBgLottie0: {},
      navBgLottie1: {},
      navBgLottie2: {},
      navBgLottie3: {},
    }
  },
  mounted() {
    this.starNavBgAni()
  },
  methods:{
    // 初始化动画
    starNavBgAni(){
      for(let i = 0; i<=3; i++){
        this[`navBgLottie${i}`] = lottie.loadAnimation({
          container: document.getElementById('navBg' + i),
          renderer: 'svg',
          loop: false,
          animationData: navAmi,
          autoplay: false
        });
      }
    },

    // 导航鼠标移入移出动画
    mouseenterNavAni(index){
      this[`navBgLottie${index}`].play()
    },
    mouseeleaveNavAni(index){
      this[`navBgLottie${index}`].stop()
    },
  }
}
</script>

<style scoped lang="scss">
  .holder{
    height: 88px;
    width: 100%;
  }
  .header{
    position: fixed;
    top: 0;
    left: 0;
    height: 88px;
    width: 100%;
    z-index: 1000;
    background: #FFFFFF;
    //border-bottom: 1px solid #CCCCCC;
    .centerContent{
      margin: 0 auto;
      //width: 1580px;
      padding:0 100px;
      display: flex;
      justify-content: space-between;
      .logo{
        width: 115px;
        vertical-align: top;
        margin-top: 10px;
      }
      .nav{
        position: relative;
        top: 30px;
        ul{
          .active{
            //background: url('../assets/img/navBg.png') no-repeat;
            background-position: 0 29px;
            color: #FEA642;
          }
          li{
            display: inline-block;
            width: 123px;
            height: 50px;
            position: relative;
            text-align: center;
            margin-right: 20px;
            /* line-height: 61px; */
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
            &:hover{
              a{
                color: #FEA642;
              }
            }
            i{
              position: absolute;
              top: 40px;
              width: 100%;
              left: 0;
              pointer-events: none;
            }
            a{
              display: block;
              height: 58px;
              &:hover{
                //color: #FEA642;
              }
            }
          }
        }
      }
    }

  }
</style>