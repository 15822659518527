<template>
  <div id="app">
<!--    <header-wrapper></header-wrapper>-->
      <router-view class="container"></router-view>
  </div>
</template>

<script>
import headerWrapper from '@/components/header'
import footerWrapper from '@/components/footer'
export default {
  components: { headerWrapper, footerWrapper },
  data(){
    return{

    }
  },

}

</script>

<style scoped lang="scss">

</style>