<template>
  <div class="footer">
    <div class="footerCenter">
      <div class="left">
        <img src="../assets/img/logo.png" alt="">
        <ul>
          <li v-for="(item, index) in routerList" :key="index">
            <router-link :to="{ path: item.path }" >{{ item.name }}</router-link>
          </li>
        </ul>
      </div>
      <div class="right">
<!--        <a href="">-->
          <img src="../assets/img/ios.png" alt="">
<!--        </a>-->
<!--        <a href="">-->
          <img src="../assets/img/android.png" alt="" style="margin-top: 19px;">
<!--        </a>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      routerList: [
        { path: '/index', name: '关于我们'},
        { path: '/companyCulture', name: '企业文化'},
        { path: '/joinUs', name: '加入我们'},
        { path: '/contactUs', name: '联系我们'},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer{
    //border-top: 1px solid #CCCCCC;
    height: 270px;
    background: #F7F7F7;
    .footerCenter{
      //width: 1515px;
      padding:0 100px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .left{
        margin-top: 90px;
        img{
          width: 147px;
        }
        ul{
          display: inline-block;
          vertical-align: top;
          margin-top: 32px;
          margin-left: 152px;
          li{
            font-size: 20px;
            //font-family: Microsoft YaHei;
            font-weight: 400;
            color: #8A888A;
            display: inline-block;
            border-right: 1px solid #8A888A;
            padding-left: 22px;
            padding-right: 27px;
            &:last-child{
              border-right: none;
            }
            a{
              color: #8A888A;
            }
          }
        }
      }
      .right{
        margin-top: 61px;
        img{
          width: 196px;
          display: block;
        }
      }
    }
  }
</style>